import React from "react"

import "../style/bulma.css"
import "../style/coolharbour-tech.css"
import "../style/coolharbour-gentext.css"
import "../style/coolharbour-tech-internal-livelab.css"


import Layout from "../components/layout"
import Labinfomanagementsystems from "../components/internalpages/laboratoryinfosystem/labinfomanagementsystem"

import Contactform from "../components/homepage/contactform"
import SEO from "../components/seo"
import Coolfooter from "../components/coolfooter"
import Bluebackstatement from "../components/extras/bluebackstatement"

const statement = ["The features and uses of a LIMS have evolved over the years from simple sample tracking to an enterprise resource planning tool that  ", <span>manages multiple aspects of laboratory informatics</span>];
const Labinfosystems = () => (
  <Layout>
    <SEO title="Laboratory Information Management System" />
    <Labinfomanagementsystems />
    <Bluebackstatement PassStatement = {statement}/>
   <Contactform />
   <Coolfooter />
  </Layout>
)

export default Labinfosystems
