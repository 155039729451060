import React, { Component } from 'react';

class bluebackstatement extends Component {

    render() {
        return (
            <div>
                <section className="bluebackstatement">
        <p>{this.props.PassStatement}</p>
                </section>
            </div>
        );
    }
}

export default bluebackstatement;

