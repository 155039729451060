import React, { Component } from 'react';
import Lapback from "../../../images/screenshots/datamanagementportal_b.jpg";
import WaterBlaze from "../../../images/water7.jpg";
import Lims from "../../../images/screenshots/lims.png";

const styles = {
	backbanner: {
		backgroundImage: `url(${WaterBlaze})`
	},
	hotbackpic: {
		backgroundImage: `url(${Lapback})`
	}
};

class Labinfomanagementsystem extends Component {
	render() {

		return (
			<div>
				<section className="internalbanner" style={styles.backbanner}>

					<div className="overfunk"></div>

					<div className="container">
						<div className="columns is-marginless">

							<div className="column is-half">
								<p className="topsubtitle">A modern LIMS for the packaging industry</p>
								<h1 className="midsize">Laboratory Information Management System</h1>
								<p className="undersubtitle">Data capture, reporting, inventory and more</p>
							</div>

						</div>
					</div>
				</section>

				<section className="under-internalbanner graddown">
					<div className="threeboxes">
						<div className="container">
							<div className="boxcard norise">
								<div className="columns is-marginless">
									<div className="column is-10">

										<h2>Supporting the modern packaging laboratory</h2><hr />
	<p>A laboratory information management system (LIMS), software-based solution with features that support a modern Packaging laboratory's operations. </p>
									</div>
								</div>


								<div className="columns is-marginless">
									<div className="column is-6 pictext">
										<h3>Enterprise-level resource planning tool</h3>
										<p className="bigpee">Key features include but are not limited to</p>
										<ul>
                                            <li>Workflow and data tracking support</li>
                                            <li>Flexible architecture</li>
                                            <li>Data exchange interfaces</li>
                                            <li>Data capture</li>
                                            <li>Reporting</li>
                                            <li>Equipment inventory</li>
										</ul>
									</div>
									<div className="column is-5 is-offset-1 pictext">
										<figure className="fullimage">
											<img src={Lims} alt="Tablet screenshot" />
										</figure>
									</div>
								</div>

							</div>
						</div>
					</div>
				</section>

			</div>
		);
	}
}

export default Labinfomanagementsystem;